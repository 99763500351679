/*

Canvas Wrapper / Apps

*/

.canvas-sketch {
  margin: 3em 0;

  canvas,
  iframe,
  svg {
    line-height: normal;
    order      : -1;
  }
}

.longform .canvas-sketch {
  @extend .responsiveGrid;

  grid-column: 1 / 13;
  // margin     : 3em 0;
  width      : 100%;
  max-width  : none;

  canvas {
    grid-column: 2 / 13;
  }

  &.wide canvas,
  &.wide iframe,
  &.wide svg {
    grid-column: 1 / 13;
    width      : 100%;
  }
}

/* Canvas Wrapper */

.sketch-wrapper {
  grid-column: 1 / 9;
  display    : flex;
}

.canvas-sketch[data-hasui="false"] {
  .canvas-sketch__meta,
  .sketch-wrapper,
  iframe {
    grid-column: 3 / 13;
  }
}

/*
META
*/

.canvas-sketch__meta {
  grid-column   : 1 / 13;

  display       : flex;
  flex-direction: row;

  margin        : 0.7em 0.5em;

  .meta__link {
    color      : var(--color-muted);
    font-family: var(--font-mono);
    font-size  : 0.4rem;
    line-height: normal;

    cursor     : ne-resize;
  }

  .meta__saver {
    border        : none;
    padding       : inherit;

    display       : flex;
    flex-direction: row;
  }

  .meta__filename {
    margin          : 0 1rem;

    display         : flex;
    flex-direction  : row;
    align-items     : center;

    font-family     : var(--font-mono);
    font-size       : 0.4rem;
    line-height     : normal;

    background-color: white;
    border-bottom   : 1px solid var(--color-muted);

    input[name="filename"],
    select[name="extention"] {
      margin       : 0;
      border       : none;
      border-radius: none;

      color        : var(--color-muted);
    }

    input[name="filename"] {
      text-align  : right;
      margin-right: 1em;
    }

    select[name="extention"] {
      margin          : 0;
      border          : 0;
      text-align      : left;

      background-color: white;
      border-radius   : none;
    }
  }

  button[name='save'] {
    margin   : 0 0.1em;
    font-size: 0.5rem;
    // font-size: inherit;
  }
}

/*
Input Elements
*/

.canvasUI {
  grid-column     : 9 / 13;

  padding         : 1em;

  background-color: #f4f4f4;

  font-family     : var(--font-mono);
  font-size       : 0.8rem;

}

.canvasUI label {
  display    : block;
  margin     : 0.25em 0 0;
  line-height: 1.25em;
  max-width  : 150px;

  .input__wrapper {
    display       : flex;
    flex-direction: column;
  }

  .input__annotation {
    width          : 100%;
    display        : flex;
    justify-content: space-between;

    font-size      : 0.5rem;
    line-height    : normal;

    .input__annotation__value {
      font-weight: bold;
    }
  }
}

.canvasUI {
  fieldset {
    padding: 0;
    margin : 1em 0 0;
    width  : 100%;
    border : none;

    legend {
      font-family: var(--font-sans);
      font-weight: bold;
    }
  }

  input[type="range"] {
    margin : 0.4em 0 0.2em;
    display: block;
  }

  button,
  input[type='button'] {
    margin: 0.2em 0;
    // color: red;
  }

  input[type=color],
  input[type=email],
  input[type=number],
  input[type=tel],
  input[type=text],
  input[type=url] {
    color: var(--color-muted);
    width: auto;
  }
}

label input[type=number] {
  display       : flex;
  flex-direction: column-reverse;
}

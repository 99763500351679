figure {
  margin: 0;

}

figcaption {
  display       : flex;
  flex-direction: column;
  // align-items   : flex-end;

  margin        : 0 1em 0 0;

  font-size     : 0.6rem;
  line-height   : normal;
  color         : var(--color-muted);

  &::before {
    // align-self: flex-start;
    content: "↑";
  }
}

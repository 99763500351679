/*
LONGFORM

*/

.longform {

  --max-content-width : 50ch;
  --side-margin : 10vw;
  // @extend .responsiveGrid;

  // max-width    : 900px;
  max-width  : 100vw;

  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;

  counter-reset: sidenotes;
  line-height  : 1.7rem;

  & > * {
    // grid-column: 3 / 9;
    // width      : 100%;
    max-width  : var(--max-content-width);
    // max-width    : 900px;
    margin     : 1em var(--side-margin);
  }

  & > .sidenotes {
    width: 100%;
    // grid-column: 10 / 13;
  }
}

.longform > hgs-figure,
.longform > sketch-comp {
  width      : 100%;
  max-width  : 100vw;
  margin: 0;
}

.longform > sketch-comp {
  &::part(sketch) {
    max-width  : var(--max-content-width);
    margin     : 0 var(--side-margin);
  }
  &::part(footer) {
    margin     : 0 var(--side-margin);
  }
}

.longform > hgs-figure {
  &::part(figure) {
    max-width  : var(--max-content-width);
    margin     : 0 0 0 var(--side-margin);
  }

}

.longform code {
  font-size       : 0.9rem;
  background-color: rgba(0,0,0,0.1);
}

.longform pre {
  display         : block;
  // width           : 180ch;
  padding         : 0.5em 0 0.5em 1em;
  border-left     : black solid 1px;
  background-color: rgb(235, 235, 235);

  code {
    font-size       : 0.8rem;
    line-height     : normal;
    background-color: transparent;
  }
}

.longform blockquote {
  background-color: rgba(0,0,0,0.1);
}

////////////////////////////////
// Figures

.longform figure {
  grid-column          : 1 / 13;

  display              : grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows   : auto;
  margin               : 1em 0;
  max-width            : none;

  & img {
    width      : 100%;
    grid-column: 1 / 13;
    margin     : 0 0 1em;

    @media (min-width: $width-small) {
      grid-column: 1 / 9;
    }

    @media (min-width: $width-medium) {
      grid-column: 3 / 9;
    }
  }

  & figcaption {
    grid-column   : 1 / 13;

    display       : flex;
    flex-direction: column;
    // align-items   : flex-end;

    margin        : 0 1em 0 0;

    font-size     : 0.7rem;
    line-height   : normal;
    color         : var(--color-muted);

    &::before {
      // align-self: flex-start;
      content: "↑";
    }

    @media (min-width: $width-small) {
      grid-column: 10 / 13;

      &::before {
        // align-self: flex-start;
        content: "←";
      }
    }
  }

}

.longform figure.wide {
  img {
    grid-column: 1 / 9;
    // grid-column: 3 / 13;
  }
}

.longform figure.super-wide {
  img {
    grid-column: 1 / 13;
  }

  figcaption::before {
    content: "↑";
  }
}

////////////////////////////////
// Footnotes and Sidenotes

.footnote-ref {
  // font-size: 0.7rem;
  font-size: 100%;
  color    : var(--color-muted);
}

.footnotes {
  margin     : 5em var(--side-margin) 0;

  font-size  : 0.8rem;
  line-height: normal;
  color      : var(--color-muted);

  ol {
    padding: 1em;

    // columns: 2;
  }
}

.footnotes figure {
  display       : flex;
  flex-direction: column;

  img {
    width: 50%;
  }
}

.footnote-backref {
  text-decoration: none;
}

.sidenotes {
  display: none;
  font-size  : 0.7rem;
  line-height: normal;
  color      : var(--color-muted);

  @media (min-width: $width-large) {
    display: block;
    position: absolute;
    float: right;
    right: 0;
    max-width  : 30vw;
    margin     : 0;
  }
}

.sidenote {
  counter-increment: sidenotes;

  margin           : 2em 1em 0 0;

  & p {
    margin: 0.25em 0;
  }

  code {
    font-size       : 0.6rem;
    background-color: transparent;
  }

  &::before {
    content: counter(sidenotes);
  }
}

.sidenote figure {
  display       : flex;
  flex-direction: column;

  font-style    : italic;

  figcaption::before {
    content: "";
  }
}

/*

FORM

*/


form {
  width: 100%;
}

label {
  display: flex;
  align-items: center;

  font-size: 0.7rem;
  font-weight: 500;
  line-height: normal;
  // text-transform: uppercase;

  input[type='checkbox'] {
    order: -1;
    margin-inline-end: 0.5em;
  }
}


/*
THE MAIN SCSS FILE

*/

// Fonts

// @use "~/inter-ui/default" with (
//   $inter-font-display: swap,
//   $inter-font-path: '~inter-ui/Inter (web)'
// );
// @use "~inter-ui/variable" with (
//   $inter-font-display: swap,
//   $inter-font-path: '~inter-ui/Inter (web)'
// );

// @import '/node_modules/inter-ui/inter.css';
// @include default.all;
// @include variable.all;

// @import url('https://rsms.me/inter/inter.css');
// @import '/node_modules/inter-ui/inter.css';
html { font-family: "Inter", "system-ui"; }
 
@supports (font-variation-settings: normal) {
  html { font-family: "Inter var", "system-ui"; }
}



//
:root {
  // Colors
  --color-primary  : #111;
  --color-muted    : grey;
  --color-highlight: rgb(255, 100, 0);
  // Dimentions
  --width-small    : 400px;
  --width-medium   : 700px;

  // Fonts
  --font-sans      : 'Inter var', 'Inter', Helvetica, sans-serif;
  --font-mono      : 'Roboto Mono', monospace;
}

$width-small: 400px;
$width-medium: 700px;
$width-large: 900px;
$site-background-color: rgb(250, 250, 250);

@import 'utils/normalize';
@import 'base/layout';
@import 'base/type';
@import 'base/form';
@import 'base/figure';
@import 'modules/site-header';
@import 'modules/page-header-footer';
@import 'modules/longform';
@import 'modules/canvas-sketch';

/*

*/
.site-header {
  z-index: 1000;
  margin: 1rem;
}

.site-header__branding {
  // STYLE
  font-family    : var(--font-sans);
  font-weight    : 700;
  font-size      : 1.7rem;
  line-height    : 1.5rem;
  text-decoration: none;
  color          : var(--color-muted);
  transition     : color 0.3s;
  cursor         : nw-resize;

  &:hover {
    color: var(--color-primary);
  }
}

.site-nav {
  // STRUCTURE
  margin: 1em 0;
}

.site-nav__section {
  line-height: normal;
}

.site-nav__breadcrumb {
  color          : var(--color-muted);
  font-family    : var(--font-mono);
  font-size      : 0.7rem;
  text-decoration: none;

  &.current {
    font-weight: bold;
    cursor     : se-resize;
  }

  // Add slash between breadcrumbs
  &::before {
    display    : inline-block;
    color      : #000;
    content    : '/ ';
    // font-size  : 110%;
    font-weight: normal;
    padding    : 0 0.1em 0 0;
  }
}

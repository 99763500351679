/*
BASE TYPE STYLES

*/

// html {
//   font-family: 'Inter', sans-serif;
// }
// @supports (font-variation-settings: normal) {
//   html {
//     font-family: 'Inter var', sans-serif;
//   }
// }

body {
  color: var(--color-primary);
}

blockquote {
  margin     : 1em 0;

  font-size  : 2rem;
  font-weight: 200;
  line-height: normal;

  &::after {
    content    : attr(cite);

    color      : var(--color-muted);
    font-weight: 400;
    font-size  : 0.7rem;
  }
}

code,
pre {
  font-family: var(--font-mono);
}

code {
  // font-size: 0.7rem;
}

p:first-child {
  margin-top: 0;
}

hr {
  max-width: none;
  margin   : 1em 0;
}

iframe {
  border: none;
}

mark {
  padding         : 0.25em;
  background-color: rgba(0,255,0,0.5);
}

////////////////////////////////////////////////////////////////////////////////
// Lists

ol,
ul {
  padding-left: 1.1em;
}

ul {
  line-height: normal;
}

dl {
  display        : grid;
  grid-row-gap   : 0.5em;
  grid-column-gap: 0.5em;
  padding-left   : 0;

  font-size      : 0.8rem;
}

dt {
  grid-column: 1 / 2;

  font-weight: bold;
}

dd {
  grid-column        : 2 / 3;
  margin-inline-start: 0;

  line-height        : 1.5em;
}

////////////////////////////////////////////////////////////////////////////////
// Headings & Sub-Headings

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  font-size  : 1rem;
  line-height: 1em;
  margin     : 0;

  &:first-child {
    margin-top: 0;
  }
}

.subheading {
  font-weight: 400;
  line-height: 1em;
}

h1 {
  margin        : 7em 0 1em;

  font-size     : 1.6rem;
  padding-bottom: 0.1em;
}

h1 + .subheading {
  margin-top: 0.5em;
  font-size : 2em;
}

h2 {
  margin     : 3em 0 1em;

  font-size  : 1.5rem;
  line-height: 1.2em;
}

h3 {
  margin     : 2em 0 1em;

  font-size  : 1.4rem;
  line-height: 1.2em;
}

h4 {
  margin     : 2em 0 1em;

  font-size  : 1.3rem;
  line-height: 1.2em;
}

h5 {
  margin     : 2em 0 1em;

  font-size  : 1.2rem;
  line-height: 1.2em;
}

h6 {
  margin     : 2em 0 0.5em;

  font-size  : 1rem;
  line-height: 1.2em;
}

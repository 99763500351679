/*

MAIN LAYOUT

*/

* {
  box-sizing: border-box;
}

body {
  display         : flex;
  flex-direction  : column;
  min-height      : 100vh;
  align-content   : space-between;

  background-color: $site-background-color;

  scroll-behavior: smooth;
}

main {
  display       : flex;
  flex-direction: column;
  height        : 100%;

  margin        : 2em 0;
}

.responsiveGrid {
  display       : flex;
  flex-direction: column;
  margin        : 1em;

  @media (min-width: $width-small) {}

  @media (min-width: $width-medium) {
    display              : grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows   : auto;

    margin               : 0;
  }
}

.section-listing {
  // @extend .responsiveGrid;

  // max-width: 900px;
  margin: 1em 10vw;

}

.deck {
  grid-column          : 3 / 9;
  width                : 100%;
  max-width: 800px;

  margin               : 1em 0;
  display              : grid;
  grid-template-columns: 1fr;
  grid-template-rows   : auto;
  grid-gap             : 1em;

  @media (min-width: $width-small) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: $width-medium) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: $width-large) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

.card {
  display        : flex;
  flex-direction : column;
  justify-content: flex-end;
  min-height     : 100px;
  width          : 100%;

  color          : inherit;

  font-size      : 0.6rem;
  font-weight    : bold;

  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &[data-draft="true"] {
    .card__title::after {
      content         : 'DRAFT';
      display         : inline;
      position        : relative;
      font-size       : 0.2em;
      margin-left     : 0.5em;
      padding         : 0.2em 0.4em 0.1em;
      border-radius   : 2em;
      color           : white;
      background-color: var(--color-primary);
    }
  }
}

.card__title {
  display: block;
  margin : 0.5em 0 0;
}

.card__figure {
  display    : block;
  width      : 100%;
  height     : 100%;
  min-height : 100px;
  order      : -1;
  line-height: normal;

  background : rgb(34,193,195);
  background : linear-gradient(40deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);

  img {
    width : 100%;
    height: 100%;
  }
}

.sub-deck {
  @extend .card;

  grid-column  : 1 / 3;
  padding      : 0.7em 0.1em;
  // background-color: var(--color-muted);
  min-height   : 0;
  border-top   : 0.5px solid black;
  border-bottom: 0.5px solid black;

  // color           : white;
  font-size    : 1rem;

  .card__title::after {
    content     : '→';
    display     : inline-block;
    padding-left: 0.5em;
    transform   : translateX(0);
    transition  : transform 1s ease-out;
  }

  &:hover {
    .card__title::after {
      transform: translateX(20px);
    }
  }

}

.pill {
  color           : white;
  background-color: var(--color-primary);
}

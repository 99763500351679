/*

Page Header and page-footer

*/

.page-footer,
.page-header {
  // @extend .responsiveGrid;

  margin: 1em 10vw;
}

.page-header h1 {
  grid-column: 3 / 9;
}

.time-stamp {
  grid-column   : 10 / 13;

  margin        : 5em 0 0;
  display       : flex;
  flex-direction: column;

  color         : var(--color-muted);
  font-family   : var(--font-mono);
  font-size     : 0.5rem;
  line-height   : normal;
}
